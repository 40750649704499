import React, { useContext, useEffect ,useState} from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { IoIosSettings } from "react-icons/io";
import axios from 'axios';
import utils from '../utils';
import { AuthContext } from '../App';
import Logout from '../pages/Logout';


const Compagin = ({campaign,setAuth}) => {

  const [cData,setCdata]= useState(null);
  const {token,userID} = useContext(AuthContext);
useEffect(() => {
  
  var data = JSON.stringify({
    "campaignId": campaign
  });
  
  var config = {
    method: 'post',
    url: `${utils.url}/vendor/getCampaign`,
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log("vendor details",response.data);
    // alert(JSON.stringify(response.data));
    setCdata(response.data.data);
  })
  .catch(function (error) {
    console.log(error);
    
  });
 
}, [])

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
        //   backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
          backgroundColor: theme.palette.mode === 'light' ? '#1b1c1c' : '#1b1c1c',
        },
      }));

  return (
    <div className="campagin">
        <div className="compagin-header">
            <h3>Campagin : "{cData?._id}":&nbsp;{cData?.name} </h3><span><IoIosSettings/></span><Logout setAuth={setAuth}/>
        </div>
            
            <div className="stats-box">
                <h4>Stats box</h4>
                &nbsp;
                <div className="box">
                    <div className="status-bar">
                    {/* <Box sx={{ flexGrow: 1 }}> */}
                 
                    {/* <BorderLinearProgress variant="determinate" id="progress" value={40} /> */}
                {/* </Box> */}
                    </div>
                    {/* <h4>Status : 40% (40000 / 100000)</h4> */}
                </div>
                <div className="box" style={{display:"flex"}}>
                    <div>Total Scan : {cData?.totalScans}</div>
                    <div>Total Bottles: {cData?.totalBottles}</div>
                </div>
            </div>
        </div>
  )
}

export default Compagin