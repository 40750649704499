import React, { useContext, useEffect ,useState} from "react";
import { useParams } from "react-router-dom";
import "./Retailer.css";
import Header from "../components/Header";
import axios from "axios";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DataGrid ,GridToolbar} from "@mui/x-data-grid";
import utils from "../utils";
import { Button, Modal } from "@mui/material";
import { AuthContext } from "../App";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";


function Retailer() {
  let { retailId ,campaign} = useParams();
  const [selectFilter,setSelectFilter]=useState("whole")
  const [retailerData,setRetailerData]=useState({});
  const [row,setRow]=useState([]);
  const [bottlD,setBottlD]=useState({});
  const [tScans,setTScans]=useState(0);
  const [gScans,setGScans]=useState(0);
  const [bCount,setBCount]=useState(0);
  const [selectedDelv,setSelectedDelv]=useState("");
  const [modalOpen,setModalOpen]=useState(false);

  const [fromD, setFromD] = useState("");
  const [toD, setToD] = useState("");

  const {token,userID} = useContext(AuthContext);

  const columns = [
    { field: 'id',flex:2, headerName: 'ID',headerAlign: 'center', align:"center",renderCell:(params)=>{ return <span style={{cursor:"pointer"}} onClick={()=>{setSelectedDelv(params.id);setModalOpen(true);console.log("selected delivery here",bottlD[params.id],bottlD)}}>{params.id}</span>;}},
    // { field: 'name',flex:2, headerName: 'Name',headerAlign: 'center', align:"center"},
    { field: 'totalBottles',flex:1, headerName: 'Bottles Distributed',type: 'number' ,headerAlign: 'center',align:"center"},
    {
      field: 'totalScans',
      headerName: 'Total Scans',
      type: 'number',
      flex:1,headerAlign: 'center',
      align:"center"
      // width: 150,
    },
    {
      field: 'genuineScans',
      headerName: 'Genuine Scans',
      type: 'number',
      flex:1,
      headerAlign: 'center',
      align:"center"
      // width: 150,
    },
    {
      field: "deliveryDate",
      headerName: "Delivery Date",
      type:"dateTime",
      flex:1.5,
      headerAlign: 'center',
      align:"center"
      // width: 200,
    },
    {
      field: "ScanRate",
      headerName: "ScanRate",
      type:"number",
      headerAlign: 'center',
      align:"center",
      valueGetter:(params)=>{
        // console.log("params",params);
        return Math.round(params.row.totalScans/params.row.totalBottles*100)},
        valueFormatter:(params)=>{
          // console.log(params);
          return `${params.value}%`
        },
      // width: 150,
      flex:1,
    },
    {
      field: "GenuineScanRate",
      headerName: "GenuineScanRate",
      type:"number",
      headerAlign: 'center',
      align:"center",
      valueGetter:(params)=>{
        // console.log("params",params);
        return  Math.round( params.row.genuineScans/params.row.totalBottles*100)},
        valueFormatter:(params)=>{
          // console.log(params);
          return `${params.value}%`
        },
        flex:1
      // width: 150,
    }
  ];
  useEffect(() => {
    let data = JSON.stringify({
      retailerId: retailId,
    });

    let config = {
      method: "post",
      url: `${utils.url}/vendor/searchRetailer`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("retailer data", response.data);
        setRetailerData(response.data.data);
      })
      .catch(function (error) {
        console.log("get retailer error",error);
      });
  },[]);
  useEffect(()=>{
      // fetch retail data
      getData();
  },[])

  const getData=()=>{
    let toDate=null;
    let fromDate=null;
      if(selectFilter==='whole'){
       //do something 
      
      }else if (selectFilter === "week") {
        let currentDate = new Date();
  
        // Calculate the date 7 days ago at midnight
        let sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0);
  
        // Get the ISO format of the date 7 days ago at midnight
        fromDate = sevenDaysAgo.toISOString();
      } else if (selectFilter === "month") {
        let currentDate = new Date();
  
        // Calculate the date 7 days ago at midnight
        let monthAgo = new Date(currentDate);
        monthAgo.setDate(currentDate.getDate() - 30);
        monthAgo.setHours(0, 0, 0, 0);
  
        // Get the ISO format of the date 7 days ago at midnight
        fromDate = monthAgo.toISOString();
      } else {
        fromDate = new Date(fromD.$d).toISOString();
        toDate = new Date(toD.$d).toISOString();
      }
  
  let data = JSON.stringify({
    campaignId: campaign,
    // fromDate: "2023-12-28T09:09:32.993Z",
    // toDate: "2023-12-29T09:05:32.993Z",
    fromDate,
    toDate,
    retailer:retailId
  });
  
  let config = {
    method: 'post',
    url: `${utils.url}/vendor/searchDeliveries`,
    headers: { 
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
    data : data
  };
  
  console.log("api call to config",config);
  let deliveries=[];
  let bottleDetails={};
  let totalBottles=0;
  let totalScans=0;
  let totalOrignalScans=0;
  axios(config)
  .then((response)=>{
      console.log("API Response for Retail Data ",response.data);
      response.data.data.map(delv=>{
          if(delv.bottles.length>0){
              let delhere={
                  id:delv.deliveryId,
                  deliveryDate:new Date(delv.createdAt),
                  totalBottles:0,
                  totalScans:0,
                  genuineScans:0
              };
              bottleDetails[delv.deliveryId]=delv.bottles;
              delv.bottles.map(botl=>{
                  delhere.totalBottles++;
                  totalBottles++;
                  if(botl.totalScans>0){
                      delhere.genuineScans++;
                      delhere.totalScans+=botl.totalScans;
                      totalOrignalScans++;
                      totalScans+=botl.totalScans;
                  }
  
              });
              deliveries.push(delhere);
          }
          
      })
      for(let i in  bottleDetails){ //@sagar remove this before launch
        console.log("HERE ",bottleDetails[i],i)
        bottleDetails[i]=bottleDetails[i].map(b=>{
          delete b.totalScans;
          delete b.genuineScans;
          return b;
        }
          )
      }
          setRow(deliveries);
      setBottlD(bottleDetails);
      setBCount(totalBottles);
      setGScans(totalOrignalScans);
      setTScans(totalScans);
  })
   
  }
  return (
    <div className="wholepage">
      <Header />
      <div className="retailContent">
        <div className="retailSide">
          <div className="retailSide1">
            <span>{retailId}</span>
            <div className="retailName">{retailerData?.name ? retailerData.name : "FETCHING DATA"}</div>
          </div>
          <div className="retailSide2">
          {
           retailerData.hasOwnProperty("images") ? retailerData?.images.map(img=>{
              return <img src={img.imgUrl} />
            }):null
          }
          </div>
          <div className="retailSide3">
            <div className="retailNavButton">
                <LocationOnIcon  onClick={()=>{ window.open("https://maps.google.com?q="+retailerData.location.lat +","+ retailerData.location.lon);}}/>
            </div>
            <div className="retailNavText">{retailerData?.address ? retailerData.address : "FETCHING DATA"}</div>
          </div>
          <div className="retailBox">
            <div className="retailBoxHead">Stats Box for {}</div>
            <div className="statsBoxContainer">
                <div className="statsItems">Total Bottles: {bCount}</div>
                <div className="statsItems">Total Scans: {tScans}</div>
                <div className="statsItems">Genuine Scans: {gScans}</div>
                <div className="statsItems">Scan Rate: {tScans/bCount*100}%</div>
                <div className="statsItems">Genuine Scan Rate: {gScans/bCount*100}</div>
            </div>
          </div>
        </div>
        <div className="retailInfo">
        <div className="sort">
            <select
              name="sort-date"
              id="sort-date"
              defaultValue="whole"
              onChange={(x) => {
                setSelectFilter(x.target.value);
              }}
            >
              <option value="week">Last 7 days</option>
              <option value="month">Last 30 days</option>
              <option value="whole">Whole Campagin</option>
              <option value="custom">Custom</option>
            </select>
            {selectFilter === "custom" ? (
              <>
                <div id="from-date">
                  From: &nbsp;
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      defaultValue={dayjs("2022-04-17")}
                      value={fromD}
                      onChange={(x) => {
                        setFromD(x);
                        console.log("set date", x.$d, new Date(x.$d).getTime());
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div id="to-date">
                  To: &nbsp;
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      defaultValue={dayjs("2022-04-17")}
                      value={toD}
                      onChange={(x) => {
                        setToD(x);
                        console.log("set date", x.$d);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </>
            ) : null}
            <Button onClick={() => getData()}>REFRESH DATA</Button>
          </div>
            <DataGrid
             rows={row}
             slots={{ toolbar: GridToolbar }}
             columns={columns}
             initialState={{
               pagination: {
                 paginationModel: { page: 0, pageSize: 5 },
               },
             }}
             pageSizeOptions={[5, 10]}
            />
            <Modal
  open={modalOpen}
  onClose={()=>{setModalOpen(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<div style={{width:"300px",height:"400px",display:"flex",justifyContent:"center",alignItems:"center",background:"white",margin:"auto",position:"absolute",top:"40%",left:"45%",overflowX:"auto",padding:"0 20px"}}>
<JsonView data={bottlD[selectedDelv]}  style={defaultStyles} />
</div>
</Modal>
        </div>
      </div>
    </div>
  );
}

export default Retailer;
