import React from 'react'
import Logo from '../Assets/Logo.png'
import './Header.css'
import { useNavigate} from 'react-router-dom'
function Header() {
   const  navigate=useNavigate();
  return (
    <div className="header">
            <div className="image" onClick={()=>navigate('/')}>
                <img src={Logo} alt="" />
            </div>
            <h5 onClick={()=>navigate('/')}>Adwaters ad manager</h5>
        </div>
  )
}

export default Header