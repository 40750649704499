import { Button, ButtonGroup } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { LuSearch } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import utils from "../utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { renderToString } from 'react-dom/server';
import { AuthContext } from "../App";
// scan rate - out of 100, recent delivery date

// const MarkerIcon=(num)=>{
//   return <div className="markerIcon">
//     <LocationOnIcon/>
//     <span style={{fontSize: '3px'}}>{num}</span>
//   </div>
// }

const NumberedPin = ({number} ) => {
  return (
    <svg
      width="24"
      height="36"
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Pin shape */}
      <rect x="9" y="28" width="6" height="6" fill="#E44D26" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C5.37 0 0 5.37 0 12c0 6.22 4.57 11.36 10.5 11.92V28c0 .55.45 1 1 1s1-.45 1-1v-4.08C17.43 23.36 22 18.22 22 12 22 5.37 16.63 0 12 0zM7 12c0-2.76 2.24-5 5-5s5 2.24 5 5-2.24 5-5 5-5-2.24-5-5z"
        fill="#E44D26"
      />
      {/* Number inside the pin */}
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#fff" fontSize="12">
        {number}
      </text>
    </svg>
  );
};

console.log(renderToString(<NumberedPin number={3} />));
//   const customIcon = {
//     url: NumberedPin(3), // Pass the necessary props to NumberedPin
//     scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
//   };
const RetailersList = (props) => {

  const [selectFilter, setSelectFilter] = useState("whole");
  const [rowData, setRowData] = useState([]);
  const [tBottles, setTBottles] = useState(0);
  const [tScans, setTScans] = useState(0);
  const [tOScans, setTOScans] = useState(0);
  const [campaignDetails, setCampaignDetails] = useState({});
  const [fromD, setFromD] = useState("");
  const [toD, setToD] = useState("");
  const navigate = useNavigate();
  const [toggleMaps,setToggleMaps]=useState(false);
  const [dataLoaded,setDataLoaded]=useState(false);
  const [selectedInfo , setSelectedInfo] = useState(null);


  const {token,userID} = useContext(AuthContext);

  //maps code intiate
  const { isLoaded,loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCGjZr3ycosNf2zTLYBeMhXk2IYQ_vKCBI",
  });
  const center = useMemo(() => ({ lat: 12.952745, lng:  77.622960 }), []);



  const columns = [
    {
      field: "id",
      flex: 2,
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Link to={`/retailer/${params.id}/${props.campaign}`}>
            {params.id}
          </Link>
        );
      },
    },
    {
      field: "name",
      flex: 2,
      headerName: "Name",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bottleCount",
      flex: 1,
      headerName: "Bottles Distributed",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalScans",
      headerName: "Total Scans",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      // width: 150,
    },
    {
      field: "orignalScans",
      headerName: "Genuine Scans",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      // width: 150,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      flex: 1,
      headerAlign: "center",
      align: "center",
      // description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      // width: 150,
    },
    {
      field: "lastDelivery",
      headerName: "Recent Delivery Date",
      type: "dateTime",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      // width: 200,
    },
    {
      field: "ScanRate",
      headerName: "ScanRate",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        // console.log("params",params);
        return (params.row.totalScans / params.row.bottleCount) * 100;
      },
      valueFormatter: (params) => {
        // console.log(params);
        return `${params.value}%`;
      },
      // width: 150,
      flex: 1,
    },
    {
      field: "GenuineScanRate",
      headerName: "GenuineScanRate",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        // console.log("params",params);
        return (params.row.orignalScans / params.row.bottleCount) * 100;
      },
      valueFormatter: (params) => {
        // console.log(params);
        return `${params.value}%`;
      },
      flex: 1,
      // width: 150,
    }
  ];
  useEffect(() => {
    getData();
  }, []);
  if (loadError) return "Error loading maps";
  const getData = async () => {
    let campaignDet = { ...campaignDetails };
    if (!campaignDet.hasOwnProperty("_id")) {
      let data = JSON.stringify({
        campaignId: props.campaign,
      });

      let config = {
        method: "post",
        url: `${utils.url}/vendor/getCampaign`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        data: data,
      };

      let waste = await axios(config)
        .then(function (response) {
          console.log("campaignrespnse", response.data);
          campaignDet = response.data.data;
          setCampaignDetails(response.data.data);
        })
        .catch(function (error) {
          console.log("error here in campaign call", error);
        });
    }
    // fetch retail data
    let toDate = null;
    let fromDate = null;
    if (selectFilter === "whole") {
      //do something
      fromDate = campaignDet.createdAt;
    } else if (selectFilter === "week") {
      let currentDate = new Date();

      // Calculate the date 7 days ago at midnight
      let sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      sevenDaysAgo.setHours(0, 0, 0, 0);

      // Get the ISO format of the date 7 days ago at midnight
      fromDate = sevenDaysAgo.toISOString();
    } else if (selectFilter === "month") {
      let currentDate = new Date();

      // Calculate the date 7 days ago at midnight
      let monthAgo = new Date(currentDate);
      monthAgo.setDate(currentDate.getDate() - 30);
      monthAgo.setHours(0, 0, 0, 0);

      // Get the ISO format of the date 7 days ago at midnight
      fromDate = monthAgo.toISOString();
    } else {
      fromDate = new Date(fromD.$d).toISOString();
      toDate = new Date(toD.$d).toISOString();
    }

    var data = JSON.stringify({
      campaignId: props.campaign,
      // fromDate: "2023-12-28T09:09:32.993Z",
      // toDate: "2023-12-29T09:05:32.993Z",
      fromDate,
      toDate,
    });

    var config = {
      method: "post",
      url: `${utils.url}/vendor/searchDeliveries`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      data: data,
    };

    console.log("api call to config", config);
    let retailData = {};
    let totalBottles = 0;
    let totalScans = 0;
    let totalOrignalScans = 0;
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        response.data.data.map((delivery) => {
          if (delivery.bottles.length > 0) {
            if (!retailData.hasOwnProperty(delivery.retailer)) {
              retailData[delivery.retailer] = {
                id: delivery.retailer,
                name: "",
                pincode: 0,
                // bottles:[],
                totalScans: 0,
                bottleCount: 0,
                orignalScans: 0,
                lastDelivery: new Date(delivery.createdAt),
              };
              console.log(
                "look here",
                new Date(delivery.createdAt),
                new Date(delivery.createdAt).getTime()
              );
            }

            if (
              retailData[delivery.retailer].lastDelivery.getTime() <=
              new Date(delivery.createdAt).getTime()
            ) {
              retailData[delivery.retailer].lastDelivery = new Date(
                delivery.createdAt
              );
              console.log(
                "look now",
                new Date(delivery.createdAt),
                new Date(delivery.createdAt).getTime()
              );
            }
            delivery.bottles.map((bottle) => {
              // retailData[delivery.retailer].bottles.push(bottle.bottleId); //add this if this data is need in the first section
              retailData[delivery.retailer].bottleCount++;
              totalBottles++; //head column display

              if (bottle?.totalScans > 0) {
                retailData[delivery.retailer].totalScans += bottle.totalScans;
                retailData[delivery.retailer].orignalScans++;
                totalScans += bottle.totalScans; //for head column display
                totalOrignalScans++; //same
              }
            });
          }
        });

        console.log("result ", retailData);

        const getretailids = Object.keys(retailData);
        console.log("retailIds", getretailids);

        var config1 = {
          method: "post",
          url: `${utils.url}/vendor/searchRetailerBulk`,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          data: JSON.stringify({ retailIds: getretailids }),
        };
        axios(config1).then((retailresponse) => {
          console.log("got retailbulk respnse", retailresponse);
          retailresponse.data.data.map((retailer) => {
            retailData[retailer._id].pincode = retailer?.pincode
              ? retailer.pincode
              : 0;
            retailData[retailer._id].name = retailer?.name
              ? retailer.name
              : "unnamed";
            retailData[retailer._id].location=retailer.location;
          });
          const rows = Object.values(retailData);
          console.log("rows is ", rows);
          setRowData(rows);
          setTBottles(totalBottles);
          setTScans(totalScans);
          setTOScans(totalOrignalScans);
          setDataLoaded(true);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="retailers">
      <div className="retailers-list">
        <h3>RETAILERS LIST</h3>
        <ButtonGroup
          id="retailers-list-btns"
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Button  onClick={()=>setToggleMaps(false)} className="btn-list">List</Button>
          <Button onClick={()=>setToggleMaps(true)}>Map</Button>
        </ButtonGroup>
      </div>
      <div className="retailers-data">
        <div className="search-filter">
          <div className="sort">
            <select
              name="sort-date"
              id="sort-date"
              defaultValue="whole"
              onChange={(x) => {
                setSelectFilter(x.target.value);
              }}
            >
              <option value="week">Last 7 days</option>
              <option value="month">Last 30 days</option>
              <option value="whole">Whole Campagin</option>
              <option value="custom">Custom</option>
            </select>
            {selectFilter === "custom" ? (
              <>
                <div id="from-date">
                  From: &nbsp;
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      defaultValue={dayjs("2022-04-17")}
                      value={fromD}
                      onChange={(x) => {
                        setFromD(x);
                        console.log("set date", x.$d, new Date(x.$d).getTime());
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div id="to-date">
                  To: &nbsp;
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      defaultValue={dayjs("2022-04-17")}
                      value={toD}
                      onChange={(x) => {
                        setToD(x);
                        console.log("set date", x.$d);
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </>
            ) : null}
            <Button onClick={() => getData()}>REFRESH DATA</Button>
          </div>
          <div className="info">
            Total Bottles Distributed: {tBottles} &nbsp; &nbsp; Total Scans : {tScans}{" "}
            &nbsp; &nbsp; Genuine Scans : {tOScans}  &nbsp; &nbsp; ScanRate:{tScans/tBottles*100}
          </div>
        </div>

        <div style={{ height: 370, width: "100%" }}>
          {
            toggleMaps&isLoaded&dataLoaded ? 
            (
              <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={10}
            >
              {/* <Marker position={{ lat: 12.938914, lng: 77.625834 }} /> */}
              {rowData.map((retailer) => (
            <Marker
              key={retailer.id}
              position={{
                lat: retailer.location.lat, // Assuming location has lat and lng properties
                lng: retailer.location.lon,
              }}
              onClick={()=>setSelectedInfo(retailer)}
              // onMouseOver={()=>setSelectedInfo(retailer)}
              // onMouseOut={()=>setSelectedInfo(null)}
              onDblClick={()=>navigate(`/retailer/${retailer.id}/${props.campaign}`)}
              label={{
                text:"tb:"+retailer.bottleCount+"|ts:"+retailer.totalScans,
                className:"markerstyle",
                color:"white",
                fontSize:"14px",
                
              }}
            />
          ))}

{selectedInfo && (
          <InfoWindow
            position={{ lat: selectedInfo.location.lat, lng: selectedInfo.location.lon }}
            onCloseClick={()=>setSelectedInfo(null)}
          >
            <div>
              <h3>{selectedInfo.name}</h3>
              <p><address>{selectedInfo.address}</address></p>
              <p>Bottle Distributed: {selectedInfo.bottleCount} Total Scan: {selectedInfo.totalScans}</p>
              <Button  onClick={()=>navigate(`/retailer/${selectedInfo.id}/${props.campaign}`)} className="btn-list">MORE DETAILS</Button>
            </div>
          </InfoWindow>
        )}
            </GoogleMap>)
            :<DataGrid
            rows={rowData}
            slots={{ toolbar: GridToolbar }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
          />
          }
        </div>
      </div>
    </div>
  );
};

export default RetailersList;
