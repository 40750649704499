import React, { useState } from 'react'
import Header from '../components/Header'
import { Button, TextField } from '@mui/material'
// import AWS from 'aws-sdk';
import './Login.css';
// import {useHistory} from 'react-router-dom'
import * as AWSCognito from 'amazon-cognito-identity-js';
// import { useNavigate } from 'react-router-dom';
function Login({isAuth,setIsAuth,token,setToken}) {
    // const history = useHistory();
    const [email,setEmail]=useState("");
    const [pass,setPass]=useState("");
    // const navigate = useNavigate();
    const signIn=()=>{
        const pool = {
            UserPoolId: "ap-south-1_XyfCTZPsq",
           ClientId: "57jlrk7k8dtg60a35a420bve9d"
        }
        const userPool = new AWSCognito.CognitoUserPool(pool);
    
        const authDetails = new AWSCognito.AuthenticationDetails({
          Username: email,
          Password: pass
        });
  
        const cognitoUser = new AWSCognito.CognitoUser({
          Username: email,
          Pool: userPool
        });
      
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: result => {
            let tokenhere= result.getIdToken().getJwtToken();
           
            console.log("logged in",tokenhere);
            setToken(tokenhere);
            setIsAuth(true);
            // navigate("/");
          },
          newPasswordRequired:(a,b)=>{
            console.log("new password required",a,b);
            delete a.email_verified;
            delete a.email;
            cognitoUser.completeNewPasswordChallenge(
                `${pass}123`,
                a
            ).then(d=>{setIsAuth(true);setToken(d.getIdToken().getJwtToken())});
            // navigate("/");

          }
          ,onFailure: err =>{
            console.log("failed to login ",err);
            alert("INVALID CREDENTIALS");
          }
          
        })}
  return (
    <div className='wholehere'>

        {/* <Header></Header> */}
        <div className='logincontainer'>
            <div className='logintitle'>LOGIN TO AD WATERS AD-MANAGER ACCOUNT</div>
            <div className='loginimg'></div>
            <div className='logintxt'>
            <TextField label="email" type="email" value={email} onChange={(e)=>setEmail(e.target.value)}></TextField>

            <TextField label="password" type='password' value={pass} onChange={(e)=>setPass(e.target.value)}></TextField>
            </div>
            <Button onClick={()=>signIn()}>SIGNIN</Button>
        </div>
    </div>
  )
}

export default Login