import React, { useContext, useEffect,useState } from 'react'
import Header from '../components/Header'
import axios from 'axios';
import utils from '../utils';
import { useNavigate} from 'react-router-dom'
import './Campaign.css'
import { AuthContext } from '../App';

function Campaign() {
  const[selectCampaign,setSelectCampaign]=useState("");
  const[campaignData,setCampaignData]=useState({});
  const {token,userID} = useContext(AuthContext);
  const navigate = useNavigate();
useEffect(()=>{
  
var data = JSON.stringify({
  // "vendorId": "658bfa7bc49ebe6398d9e1e8"
  vendorId:userID
});

var config = {
  method: 'post',
  url: `${utils.url}/vendor/getVendor`,
  headers: { 
    'Content-Type': 'application/json',
    "Authorization": `Bearer ${token}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log("vendor details",response.data);
  // alert(JSON.stringify(response.data));
  setCampaignData(response.data.data);
})
.catch(function (error) {
  console.log(error);
  
});

},[])
  return (
    <div className='container'style={{width:"100%",height:"100%"}}>
      <Header></Header>
      <div className='companyDetails'>{campaignData.name}</div>
      <div className='select'>SELECT YOUR CAMPAIGN</div>
      <div className='campaignBox'>
      <div className='campaignContainer'>
        {
          campaignData?.campaigns && campaignData.campaigns.map(camp=>{
            return <div className='campaignItem' onClick={()=>{navigate(`/admanager/${camp._id}`)}}>
              <div className='campaignDetails'>
                <div className='campaignId'>{camp._id}</div>
                <div className='campaignName'>{camp.name}</div>
              </div>
              <div className='campaignInfo'>
                city:{camp?.city ? camp.city :"bangalore"}
                &nbsp;|&nbsp; TotalBottles:{camp.totalBottles}
              </div>
            </div>
          })
        }
      </div>
      </div>
      
    </div>
  )
}

export default Campaign