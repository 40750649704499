import React, { useEffect ,useState} from 'react'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import RetailersList from '../components/RetailersList';
import "../pages/AdManager.css"
import Compagin from '../components/Compagin';
import Header from '../components/Header';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import utils from '../utils';
const AdManager = ({setAuth}) => {
  const {campaign}=useParams();
  
  useEffect(()=>{


  })
  return (
    <div className="container">
       <Header/>

        <div className="content-container">
            <Compagin campaign={campaign}/>
            <RetailersList campaign={campaign}/>
        </div>
         
    </div>
  )
}

export default AdManager