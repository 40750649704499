import React from "react";
import * as AWSCognito from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

function Logout(setAuth) {
  return (
    <button
      onClick={() => {
        // const pool = {
        //   UserPoolId: "ap-south-1_cAudLwTKm",
        //   ClientId: "3ec80bl3i2f1psfap1mjer5n01",
        // };
        const pool = {
          UserPoolId: "ap-south-1_XyfCTZPsq",
         ClientId: "57jlrk7k8dtg60a35a420bve9d"
      }
        const userPool = new AWSCognito.CognitoUserPool(pool);
        var currentUser = userPool.getCurrentUser();

        if (currentUser) {
          // Sign out the user
          currentUser.getSession((err, result) => {
            if (result) {
              currentUser.globalSignOut({
                onSuccess: (result) => {
                  // alert("Worked");
                  setAuth(false);
                },
                onFailure: (err) => {
                  console.log("error", err);
                },
              });
            }
          });
        } else {
          console.log("No user is currently signed in.");
        }
      }}
    >
      logout
    </button>
  );
}

export default Logout;
