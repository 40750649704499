import React, { createContext, useEffect, useState } from 'react'
import AdManager from './pages/AdManager'
import { Route, Routes, useHistory, useLocation, BrowserRouter  } from 'react-router-dom';
import {} from 'react-router-dom';
import Campaign from './pages/Campaign';
import Retailer from './pages/Retailer';
import Login from './pages/Login';
import Guard from './pages/Guard';
// import "./global.css"
import * as AWSCognito from 'amazon-cognito-identity-js';

export const AuthContext= createContext("");


const App = () => {
  const [isAuth,setIsAuth]=useState(false);
  const [tken,setTken]=useState("");
  const [idC,setIdC]=useState("");

  useEffect(() => {
    const pool = {
      UserPoolId: "ap-south-1_XyfCTZPsq",
     ClientId: "57jlrk7k8dtg60a35a420bve9d"
  }
    let userPool = new AWSCognito.CognitoUserPool(pool);
    let cognitoUser = userPool.getCurrentUser();
    
    if (cognitoUser != null) {
      cognitoUser.getSession(function(err, session) {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          setIsAuth(false)
        }
        console.log('session validity: ' + session.isValid());
        
      
        if(session.isValid());{
          
          console.log("session",session);
          setTken(session.getIdToken().getJwtToken());
          setIdC(session.idToken.payload.sub);
          setIsAuth(true);
        }
      })
      
  }else{
    setIsAuth(false);
  }

}, [])
  if(isAuth){
    return (
      <AuthContext.Provider value={{token:tken,userID:idC}}>
      <BrowserRouter>
      <Routes>
      {/* <Route path="/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth}/>}  /> */}
      {/* <Route element={<Guard isAuth={isAuth}/>}> */}
        <Route path="/admanager/:campaign" element={<AdManager setAuth={setIsAuth}/>}  exact={true} />
        <Route path="/" element={<Campaign/>}   />
        <Route path='/retailer/:retailId/:campaign' element={<Retailer></Retailer>} />
      {/* </Route> */}
      </Routes>
      </BrowserRouter>
      </AuthContext.Provider>
    )
  }
  else{
    return <Login isAuth={isAuth} setIsAuth={setIsAuth} token={tken} setToken={setTken}></Login>
  }
}

export default App